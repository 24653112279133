import Web3 from 'web3'
import { HttpProviderOptions } from 'web3-core-helpers'
import getBlockNode from './getBlockNode'

/**
 * Provides a web3 instance using our own private provider httpProver
 */
const getWeb3 = (chainId: number) => {
  const RPC_URL = getBlockNode(chainId) + '8adfdee257df4294b9d7ac4a76c0c28f'
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL, { timeout: 10000 } as HttpProviderOptions)
  const web3 = new Web3(httpProvider)
  return web3
}

export { getWeb3 }
